const getVoices = (selectedLanguage, getFilteredVoices, t) => {
    const voices = getFilteredVoices.map((voice, i) => {
        return {
            value: i,
            label:
                voice.label == 'MALE'
                    ? t(selectedLanguage + '.' + voice.voiceCode)
                    : t(selectedLanguage + '.' + voice.voiceCode),
            gender: voice.label,
            voiceCode: voice.voiceCode,
            selected: voice.selected
        }
    })
    return voices
}
export { getVoices }